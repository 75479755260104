.container {
    display: flex;
    min-height: 100vh;
    color: #fff;
    background-color: #000000; /* Dark grey background for the whole page */
  }
  
  .left-column {
    flex: 1;
    padding: 20px;
    border-right: 1px solid #555; /* Subtle division between columns */
  }
  
  .right-column {
    flex: 2;
    padding: 50px;
  }
  
  h1, h2 {
    color: #ffffff; /* Light grey text for headers */
  }
  
  h1 {
    font-size: 4.5em;
    margin-bottom: 0.5em;
  }
  
  h2 {
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 2em;
  }
  
  .form-container {
    margin-top: 20px;
    padding: 15px;
    padding-left: 0;
    padding-right: 0;
    border-radius: 8px;
  }
  
  input, button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
  }
  
  input {
    background-color: rgb(0, 0, 0);
    color: #ddd; /* Light grey text */
    border: 1px solid #666;
  }
  
  button {
    background-color: #666; /* Medium grey for buttons */
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: #777; /* Lighter grey on hover */
  }
  
  button[type="button"] {
    background-color: #555; /* Darker button for less important actions */
  }
  
  button[type="button"]:hover {
    background-color: #666;
  }

  button[type="submit"] {
    background-color: #306575;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
  }
  